<template>
  <div id="app-layout">           
    <div class="home__title">
              <h2 class="home__title__bg">CONTACT</h2>
    </div>         
    <div class="pagecontent">
        <section class="maps">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11540.488904522415!2d-1.3543598!3d43.6872223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcc212946c3aec03!2sOffice%20notarial%20de%20Me%20Ang%C3%A9lique%20Montagner!5e0!3m2!1sfr!2sfr!4v1613212714001!5m2!1sfr!2sfr" width="100%" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </section>
        <section class="contact">
                  <div class="contact__findus">
                    <h3 class="contact__title">Nous trouver, nous contacter :</h3>
                    <ul class="contact__list">
                      <li class="contact__link"><i class="fas fa-map-marker-alt contact__icon"></i><p class="contact__link__text">12 Avenue de Laubian,<br>40510 Seignosse</p></li>
                      <li class="contact__link"><a class="contact__link__text" href="mailto:angelique.montagner@notaires.fr"><i class="far fa-envelope contact__icon"></i>angelique.montagner@notaires.fr</a></li>
                      <li class="contact__link"><a class="contact__link__text" href="tel:+33558583515"><i class="fas fa-phone contact__icon"></i>05 58 58 35 15</a></li>
                    </ul>
                  </div>
                  <div class="contact__horaires">
                      <h3 class="contact__title">Horaires :</h3>
                      <ul class="contact__list">
                        <li>Du lundi au vendredi,</li>
                        <li>de 8h45 à 12h30 et de 13h30 à 18h30</li>
                        <li><i class="fas fa-wheelchair contact__icon"></i>&nbsp;Accès handicapé</li>
                        <li><i class="fas fa-parking contact__icon"></i>&nbsp;Parking disponible</li>
                      </ul>
                  </div>                  
          </section>
          <section id="formulaire">
            <h2 class="form__title">Formulaire de contact</h2>
              <form class="contactForm" id="contactform" method="post" @submit="onSubmit">
    <div class="form-group">
        <label class="form-label" for="nom">Nom</label>
        <input id="nom" class="form-control" pattern="[A-Za-zÀ-ÖØ-öø-ÿ' ._-]+$" minlength="2" maxlength="40" type="text" name="nom" required>
    </div>
    <div class="form-group">
        <label class="form-label" for="prenom">Prénom</label>
        <input id="prenom" class="form-control" pattern="[A-Za-zÀ-ÖØ-öø-ÿ' ._-]+$" minlength="2" maxlength="40" type="text" name="prenom" required>
    </div>
    <div class="form-group">
        <label class="form-label" for="email">Email</label>
        <input id="email" class="form-control" type="email" minlength="2" maxlength="40" name="email" required>
    </div>
    <div class="form-group">
        <label class="form-label" for="tel">Téléphone</label>
        <input id="tel" class="form-control" pattern="[0-9 .+]+$" type="text" name="tel" required>
    </div>
    <div class="form-group">
        <label class="form-label" for="prenom">Message</label>
        <textarea id="message" class="form-control" name="message" minlength="2" maxlength="500" rows="6" required></textarea>
    </div>
        <input class="form__button" type="submit">
    </form>
    </section>
    <transition name="fade" mode="out-in">
          <div class="successmessage" v-if="sent">
              <p>Votre message a bien été envoyé.</p>
          </div>
          <div class="errormessage" v-if="error">
              <p>Une erreur est survenue, merci de rééssayer ou d'envoyer un mail à <a href="mailto:officedelaubian.seignosse@notaires.fr">officedelaubian.seignosse@notaires.fr</a></p>
          </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; 

export default {
  metaInfo: {
    // Children can override the title.
    title: 'Contact - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Contact'}
   ]
  },
    data() {
        return {
          sent: false,
          error: false
        };
    },
    methods: {
      onSubmit(e) {
          e.preventDefault();
            const nom = document.getElementById("nom").value; // récupération du nom dans l'input d'id nom
            const prenom = document.getElementById("prenom").value; // récupération du prenom dans l'input d'id prenom
            const tel = document.getElementById("tel").value; // récupération du mot de passe dans l'input d'id password
            const email = document.getElementById("email").value;
            const message = document.getElementById("message").value;

            const formData = new FormData(); 
 
            formData.append('nom', nom); 
            formData.append('prenom', prenom); 
            formData.append('tel', tel); 
            formData.append('email', email); 
            formData.append('message', message); 

            axios.post("mail.php", formData,
                        {
                            headers: { // headers de la requete
                                'Content-Type': 'multipart/form-data'
                            }
                        }
             )
             .then(res => {
                  if(res.status === 200) { // Requete réussie
                            this.sent = true;
                 console.log(res);
                 document.getElementById("contactform").reset();
                        }
                    })
             .catch(res => {
                        this.error = true;
                        console.log(res);
                        
             });
      }
    }
};
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.contact {
  &__icon {
    color: $tertiary-color;
  }
}

#formulaire {
  width: 80%;
  margin: auto;
  @media screen and (max-width: 979px){
        width: 98%;
    }
}

.error-message {
    text-align: center;
    margin-bottom: 30px;
}

.loginForm {
    display: block;
    margin: auto;
    width: 80%;
    max-width: 200px;
}

.form-control {
    display: block;
    border-radius: 5px;
    border: 1px solid $tertiary-color;
    margin-bottom: 10px;
    width: 100%;
    padding: 3px;
    box-sizing: border-box;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
}

.form__button {
    text-decoration: none;
    background-color: $tertiary-color;
    padding: 5px;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    font-weight: bolder;
    transition: all 250ms;
    width: 100%;
    border: none;
    margin-bottom: 50px; // à supprimer
    &:hover {
        background-color: lighten($color: $tertiary-color, $amount: 10);
        box-shadow: 0px 0px 4px 0px #6f7070;
    }
}

.form__title {
      margin: auto;
      text-align: center;
      margin-bottom: 30px;
      background-color: $tertiary-color;
      border-radius: 3px;
      display: block;
      width: fit-content;
      padding: 5px 10px;
}

.errormessage {
  text-align: center;
  margin-top: 10px; }

  .successmessage {
  text-align: center;
  margin-top: 10px; }
</style>